import { Link } from "react-router-dom";

const CreativeAgencyTwoFooter = () => {
  return (
    <>
      <div className="ca-two-footer ca-two-bg-five position-relative z-1">
        <div className="container-fluid">
          <div className="container">
            <div className="ca-two-footer-area pb-60 position-relative z-3">
              <div className="row justify-content-between">
                <div className="col-xl-3 col-lg-5">
                  <div className="footer-single-col">
                    <img
                      src="assets/img/creative-agency-2/nav-logo.png"
                      alt=""
                    />
                    <p className="text-white flh-24 mt-20 mb-30">
                      Interactively disseminate client-based functionalities and
                      resource.
                    </p>
                    <Link
                      href="assets/img/creative-agency-two"
                      className="ca-two-call-btn ca-two-bg-six ca-two-body-clr-two ca-border-hover-color d-inline-flex align-items-center gap-2 rounded-16 pr-20"
                    >
                      <div className="call-btn bg-white d-flex align-items-center justify-content-center">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.6125 3.65994C9.29514 3.60131 9.00367 3.80384 8.94325 4.11363C8.88284 4.42342 9.086 4.72588 9.39473 4.7865C10.3242 4.96771 11.042 5.68722 11.2239 6.61991C11.2757 6.88839 11.512 7.08426 11.7842 7.08426C11.8207 7.08426 11.8573 7.08093 11.8944 7.07426C12.2032 7.01231 12.4063 6.71051 12.3459 6.40006C12.0744 5.00702 11.0021 3.93109 9.6125 3.65994Z"
                            fill="#141414"
                          ></path>
                          <path
                            d="M9.57122 1.3386C9.4225 1.31728 9.27311 1.36125 9.15427 1.45585C9.0321 1.55179 8.95575 1.69036 8.93915 1.84558C8.90396 2.15937 9.13037 2.44317 9.44374 2.47848C11.6048 2.71965 13.2846 4.40316 13.5276 6.57101C13.5601 6.86147 13.8038 7.08066 14.0946 7.08066C14.1165 7.08066 14.1378 7.07932 14.1597 7.07666C14.3117 7.06 14.4472 6.98472 14.5428 6.8648C14.6377 6.74489 14.6809 6.59566 14.6636 6.44309C14.3608 3.73828 12.2675 1.63906 9.57122 1.3386Z"
                            fill="#141414"
                          ></path>
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.35466 8.64826C10.014 11.3069 10.6173 8.23115 12.3106 9.92321C13.943 11.5552 14.8812 11.8821 12.813 13.9498C12.5539 14.158 10.9079 16.6628 5.12323 10.8798C-0.662156 5.096 1.84122 3.4483 2.04948 3.1893C4.12274 1.1159 4.44407 2.05959 6.07649 3.69155C7.76974 5.38433 4.69527 5.98961 7.35466 8.64826Z"
                            fill="#141414"
                          ></path>
                        </svg>
                      </div>
                      <h6 className="text-white fw-500 fs-20 mb-0">
                        120 5478 6980
                      </h6>
                    </Link>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-5">
                  <div className="footer-single-col pl-40">
                    <h3 className="text-white">Pages</h3>
                    <ul className="list-unstyled footer-nav-list mb-lg-0">
                      <li>
                        <Link
                          href="assets/img/creative-agency-two"
                          className="text-decoration-none"
                        >
                          Pricing
                        </Link>
                      </li>
                      <li>
                        <Link
                          href="assets/img/creative-agency-two"
                          className="text-decoration-none"
                        >
                          Blog
                        </Link>
                      </li>
                      <li>
                        <Link
                          href="assets/img/creative-agency-two"
                          className="text-decoration-none"
                        >
                          Blog Details
                        </Link>
                      </li>
                      <li>
                        <Link
                          href="assets/img/creative-agency-two"
                          className="text-decoration-none"
                        >
                          Contact
                        </Link>
                      </li>
                      <li>
                        <Link
                          href="assets/img/creative-agency-two"
                          className="text-decoration-none"
                        >
                          Career Single
                        </Link>
                      </li>
                      <li>
                        <Link
                          href="assets/img/creative-agency-two"
                          className="text-decoration-none"
                        >
                          Services Single
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-5">
                  <div className="footer-single-col pr-20">
                    <h3 className="text-white">Contact</h3>
                    <ul className="list-unstyled footer-nav-list mb-lg-0">
                      <li>
                        <Link
                          href="assets/img/creative-agency-two"
                          className="text-decoration-none d-flex align-items-center gap-4"
                        >
                          <svg
                            width="20"
                            height="18"
                            viewBox="0 0 20 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.9395 0C16.2805 0 17.5705 0.53 18.5195 1.481C19.4695 2.43 20.0005 3.71 20.0005 5.05V12.95C20.0005 15.74 17.7305 18 14.9395 18H5.06049C2.26949 18 0.000488281 15.74 0.000488281 12.95V5.05C0.000488281 2.26 2.25949 0 5.06049 0H14.9395ZM16.0705 5.2C15.8605 5.189 15.6605 5.26 15.5095 5.4L11.0005 9C10.4205 9.481 9.58949 9.481 9.00049 9L4.50049 5.4C4.18949 5.17 3.75949 5.2 3.50049 5.47C3.23049 5.74 3.20049 6.17 3.42949 6.47L3.56049 6.6L8.11049 10.15C8.67049 10.59 9.34949 10.83 10.0605 10.83C10.7695 10.83 11.4605 10.59 12.0195 10.15L16.5305 6.54L16.6105 6.46C16.8495 6.17 16.8495 5.75 16.5995 5.46C16.4605 5.311 16.2695 5.22 16.0705 5.2Z"
                              fill="#D7D7D7"
                            />
                          </svg>
                          hello@themetags.com
                        </Link>
                      </li>
                      <li>
                        <Link
                          href="assets/img/creative-agency-two"
                          className="text-decoration-none d-flex align-items-center gap-4"
                        >
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.1905 12.7301C14.7439 12.6218 14.2939 12.901 14.1881 13.3485C14.0814 13.7959 14.3589 14.2451 14.8064 14.3509C17.3946 14.9651 18.333 15.8734 18.333 16.3351C18.333 17.3309 15.0097 18.835 9.99981 18.835C4.99073 18.835 1.66665 17.3309 1.66665 16.3351C1.66665 15.8734 2.60581 14.9651 5.1924 14.3509C5.64071 14.2451 5.91739 13.7959 5.81071 13.3485C5.70489 12.901 5.2549 12.6218 4.80823 12.7301C1.70747 13.4651 0 14.7451 0 16.3351C0 19.07 5.03159 20.5017 9.99981 20.5017C14.9689 20.5017 19.9996 19.07 19.9996 16.3351C19.9996 14.7451 18.2922 13.4651 15.1905 12.7301Z"
                              fill="#D7D7D7"
                            />
                            <path
                              d="M10.0013 7.16905C10.4613 7.16905 10.8346 6.79574 10.8346 6.33575C10.8346 5.87575 10.4613 5.50244 10.0013 5.50244C9.54128 5.50244 9.16797 5.87575 9.16797 6.33575C9.16797 6.79574 9.54128 7.16905 10.0013 7.16905Z"
                              fill="#D7D7D7"
                            />
                            <path
                              d="M8.91603 16.0292L9.25521 16.7075C9.39688 16.99 9.6852 17.1683 10.001 17.1683C10.3168 17.1683 10.6052 16.99 10.7468 16.7075L11.4651 15.2709C12.3393 13.5217 13.4176 11.8276 14.4601 10.1893L14.9351 9.44179C15.5234 8.5118 15.8342 7.43764 15.8342 6.33517C15.8342 4.6752 15.1234 3.08855 13.8843 1.98193C12.646 0.876128 10.9802 0.346177 9.31939 0.541173C6.72608 0.836167 4.58448 2.92445 4.2253 5.50608C4.01281 7.03605 4.3953 8.5552 5.3011 9.78518C6.71444 11.7001 7.81773 13.8309 8.91603 16.0292ZM10.001 3.83525C11.3793 3.83525 12.501 4.95691 12.501 6.3352C12.501 7.7135 11.3793 8.83516 10.001 8.83516C8.62272 8.83516 7.50106 7.7135 7.50106 6.3352C7.50106 4.95691 8.62272 3.83525 10.001 3.83525Z"
                              fill="#D7D7D7"
                            />
                          </svg>
                          123, Western Road, MelbourneNew York City, USA
                        </Link>
                      </li>
                      <li className="mt-30">
                        <Link
                          href="assets/img/creative-agency-two"
                          className=""
                        >
                          View Google Map
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-5">
                  <div className="footer-single-col">
                    <h3 className="text-white">Newsletter</h3>
                    <p className="text-white flh-24">
                      Sign up for our latest news & articles. We won’t give you
                      spam mails.
                    </p>
                    <form className="ca-two-newsletter-form mt-40 position-relative">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your email"
                        name="email"
                        required=""
                        autoComplete="off"
                      />
                      <button className="btn"></button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="ca-two-footer-bottom position-relative z-3">
              <ul className="list-unstyled list-inline footer-social-list d-flex justify-content-center mb-0">
                <li className="list-inline-item">
                  <Link
                    href="assets/img/creative-agency-two"
                    className="ca-two-bg-seven ca-two-border ca-two-border-color-two"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    href="assets/img/creative-agency-two"
                    className="ca-two-bg-seven ca-two-border ca-two-border-color-two"
                  >
                    <i className="fab fa-instagram"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    href="assets/img/creative-agency-two"
                    className="ca-two-bg-seven ca-two-border ca-two-border-color-two"
                  >
                    <i className="fab fa-dribbble"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    href="assets/img/creative-agency-two"
                    className="ca-two-bg-seven ca-two-border ca-two-border-color-two"
                  >
                    <i className="fab fa-github"></i>
                  </Link>
                </li>
              </ul>
              <div className="text-center mt-50">
                <p className="ca-two-body-clr-two flh-24 mb-0 ch-pb-10">
                  Copyright @2023 All Rights Reserved by
                  <Link
                    className="ca-two-body-clr-two"
                    href="assets/img/creative-agency-two"
                  >
                    ThemeTags
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <svg
            className="footer-svg z-2"
            width="1652"
            height="598"
            viewBox="0 0 1652 598"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M507 32C507 14.3269 492.673 0 475 0H32C14.3269 0 0 14.3269 0 32V566C0 583.673 14.3269 598 32 598H428.5C443.136 598 455 586.136 455 571.5C455 556.864 466.864 545 481.5 545H1170.5C1185.14 545 1197 556.864 1197 571.5C1197 586.136 1208.86 598 1223.5 598H1620C1637.67 598 1652 583.673 1652 566V403.5C1652 392.73 1643.27 384 1632.5 384C1621.73 384 1613 375.27 1613 364.5V234.5C1613 223.73 1621.73 215 1632.5 215C1643.27 215 1652 206.27 1652 195.5V32C1652 14.3269 1637.67 0 1620 0H567C549.327 0 535 14.3269 535 32V116C535 123.732 528.732 130 521 130C513.268 130 507 123.732 507 116V32Z"
              fill="#010101"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default CreativeAgencyTwoFooter;

import { Link } from "react-router-dom";

const AiCompanyFooter = () => {
  return (
    <>
      <div className="aih-footer bgc-dark-1 pt-160 position-relative z-1 rounded-16">
        <div className="aih-footer-top">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-3 col-lg-5">
                <div className="footer-single-col">
                  <img src="/ai_home/logo.png" alt="" />
                  <p className="flh-24 mt-20 mb-30">
                    Interactively disseminate client-based functionalities and
                    resource-leveling Competently network equity invested
                    web-readiness{" "}
                  </p>
                  <ul className="list-unstyled list-inline footer-social-list d-flex mb-0">
                    <li className="list-inline-item">
                      <Link href="/ai-company" className="bgc-white risk-color">
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link href="/ai-company" className="bgc-white risk-color">
                        <i className="fab fa-instagram"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link href="/ai-company" className="bgc-white risk-color">
                        <i className="fab fa-dribbble"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link href="/ai-company" className="bgc-white risk-color">
                        <i className="fab fa-github"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5">
                <div className="footer-single-col pl-40">
                  <h3 className="text-white">Pages</h3>
                  <ul className="list-unstyled footer-nav-list mb-lg-0">
                    <li>
                      <Link href="/ai-company" className="text-decoration-none">
                        Pricing
                      </Link>
                    </li>
                    <li>
                      <Link href="/ai-company" className="text-decoration-none">
                        Blog
                      </Link>
                    </li>
                    <li>
                      <Link href="/ai-company" className="text-decoration-none">
                        Blog Details
                      </Link>
                    </li>
                    <li>
                      <Link href="/ai-company" className="text-decoration-none">
                        Contact
                      </Link>
                    </li>
                    <li>
                      <Link href="/ai-company" className="text-decoration-none">
                        Career Single
                      </Link>
                    </li>
                    <li>
                      <Link href="/ai-company" className="text-decoration-none">
                        Services Single
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5">
                <div className="footer-single-col pr-20">
                  <h3 className="text-white">Contact</h3>
                  <ul className="list-unstyled footer-nav-list mb-lg-0">
                    <li>
                      <Link
                        href="/ai-company"
                        className="text-decoration-none d-flex align-items-center gap-4"
                      >
                        <svg
                          width="16"
                          height="13"
                          viewBox="0 0 16 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.35"
                            d="M13.6 12.8H2.4C1.0744 12.8 0 11.7256 0 10.4V2.4C0 1.0744 1.0744 0 2.4 0H13.6C14.9256 0 16 1.0744 16 2.4V10.4C16 11.7256 14.9256 12.8 13.6 12.8Z"
                            fill="#00E1BE"
                          />
                          <path
                            d="M6.3536 6.21202L0 2.4C0 1.0744 1.0744 0 2.4 0H13.6C14.9256 0 16 1.0744 16 2.4L9.6464 6.21202C8.63278 6.82002 7.36722 6.82002 6.3536 6.21202Z"
                            fill="#00E1BE"
                          />
                        </svg>
                        hello@themetags.com
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="/ai-company"
                        className="text-decoration-none d-flex align-items-center gap-4"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.1905 12.2284C14.7439 12.1201 14.2939 12.3993 14.1881 12.8467C14.0814 13.2942 14.3589 13.7434 14.8064 13.8492C17.3946 14.4634 18.333 15.3717 18.333 15.8334C18.333 16.8292 15.0097 18.3333 9.99981 18.3333C4.99073 18.3333 1.66665 16.8292 1.66665 15.8334C1.66665 15.3717 2.60581 14.4634 5.1924 13.8492C5.64071 13.7434 5.91739 13.2942 5.81071 12.8467C5.70489 12.3993 5.2549 12.1201 4.80823 12.2284C1.70747 12.9634 0 14.2434 0 15.8334C0 18.5683 5.03159 20 9.99981 20C14.9689 20 19.9996 18.5683 19.9996 15.8334C19.9996 14.2434 18.2922 12.9634 15.1905 12.2284Z"
                            fill="#00E1BE"
                          />
                          <path
                            d="M10.0013 6.66759C10.4613 6.66759 10.8346 6.29427 10.8346 5.83428C10.8346 5.37429 10.4613 5.00098 10.0013 5.00098C9.54128 5.00098 9.16797 5.37429 9.16797 5.83428C9.16797 6.29427 9.54128 6.66759 10.0013 6.66759Z"
                            fill="#00E1BE"
                          />
                          <path
                            d="M8.91603 15.5277L9.25521 16.206C9.39688 16.4885 9.6852 16.6669 10.001 16.6669C10.3168 16.6669 10.6052 16.4885 10.7468 16.206L11.4651 14.7694C12.3393 13.0202 13.4176 11.3261 14.4601 9.68781L14.9351 8.94032C15.5234 8.01034 15.8342 6.93618 15.8342 5.8337C15.8342 4.17373 15.1234 2.58708 13.8843 1.48046C12.646 0.374663 10.9802 -0.155288 9.31939 0.039708C6.72608 0.334702 4.58448 2.42298 4.2253 5.00461C4.01281 6.53459 4.3953 8.05374 5.3011 9.28371C6.71444 11.1986 7.81773 13.3295 8.91603 15.5277ZM10.001 3.33379C11.3793 3.33379 12.501 4.45545 12.501 5.83374C12.501 7.21203 11.3793 8.33369 10.001 8.33369C8.62272 8.33369 7.50106 7.21203 7.50106 5.83374C7.50106 4.45545 8.62272 3.33379 10.001 3.33379Z"
                            fill="#00E1BE"
                          />
                        </svg>
                        123, Western Road, MelbourneNew York City, USA
                      </Link>
                    </li>
                    <li className="mt-30">
                      <Link href="/ai-company" className="">
                        View Google Map
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5">
                <div className="footer-single-col">
                  <h3 className="text-white">Newsletter</h3>
                  <p className="flh-24">
                    Sign up for our latest news &amp; articles. We won’t give
                    you spam mails.
                  </p>
                  <form className="ca-two-newsletter-form mt-40 position-relative">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your email"
                      name="email"
                      required=""
                      autoComplete="off"
                    />
                    <button className="btn"></button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rm-footer-bottom text-center pt-80 pb-30">
          <p className="text-white fs-16 ff-risk-pri flh-24 mb-0">
            Copyright @2023 All Rights Reserved by
            <Link
              href="/ai-company"
              className="it-company-color aih-color fw-700"
            >
              ThemeTags
            </Link>
          </p>
        </div>
        <div className="s-one position-absolute">
          <svg
            width="204"
            height="104"
            viewBox="0 0 204 104"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="204" height="104" rx="21" fill="white" />
            <rect y="30" width="204" height="74" fill="white" />
            <rect width="50" height="83" fill="white" />
          </svg>
        </div>
        <div className="s-two position-absolute">
          <svg
            width="204"
            height="104"
            viewBox="0 0 204 104"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="204"
              height="104"
              rx="21"
              transform="matrix(-1 0 0 1 204 0)"
              fill="white"
            />
            <rect
              width="204"
              height="74"
              transform="matrix(-1 0 0 1 204 30)"
              fill="white"
            />
            <rect
              width="50"
              height="83"
              transform="matrix(-1 0 0 1 204 0)"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default AiCompanyFooter;
